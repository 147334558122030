import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { getFormattedDateTime, getFormattedDuration, SETTINGS } from '../App';
import { CircularProgress, Typography } from "@mui/material";


type ResponseObj = Array<MarsClass>

export type MarsClass = {
  path: string;
  description: string;
  id_schema: string;
}

type Row = {
  id: string;
  description: string;
  id_schema: string;
}

const fetchSessions = (): Promise<ResponseObj> => {
  return fetch(SETTINGS.url.marsclasses)
    .then((res) => res.json());
}

export const sessionsColumnsDefinition: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Mars Class Path',
    width: 200,
    type: 'number',
    hideable: false,
    cellClassName: "monospaced-text"
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 200,
    flex: 1,
    hideable: false
  },
  {
    field: 'id_schema',
    headerName: 'Schema',
    minWidth: 200,
    flex: 0,
    hideable: false
  }
];


export default function MarsClasses() {

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [loadedData, setLoadedData] = useState<Array<Row> | null>(null);
  const [hasError, setHasError] = useState<string | null>(null);
  const [currentNid, setCurrentNid] = useState<string | null>(null);

  function sessionPopUp(e: GridRowParams<Row>) {
    setCurrentNid(e.row.id);
  }

  const getData = () => {
    setIsDataLoading(true);

    fetchSessions()
      .then((res) => {
        setLoadedData(res.map((item) => {
          // 'services' can be a string OR an object with strings as property '1', property '2' etc
          const marsClassDefinition = item;

          const services = marsClassDefinition ?
            (typeof marsClassDefinition === 'object' ? Object.values(marsClassDefinition) : [marsClassDefinition]).join(", ")
            : ''
          return {
            id: item['path'],
            description: item['description'],
            id_schema: item['id_schema'],
          }
        }))
      })
      .catch((error: any) => {
        setHasError(error.message);
      })
      .finally(() => {
        setIsDataLoading(false);
      })
  }

  useEffect(() => {
    getData();
    setInterval(getData, 30 * 1000);
  }, [])

  return (
    <Box>
      <h2 className='section-header'>Mars Classes</h2>

      <Box sx={{ height: 533, width: '100%' }}>

        {
          isDataLoading && !loadedData &&
          <CircularProgress sx={{ display: "block", margin: "10px auto" }} />
        }

        {
          hasError &&
          <Box>
            <Typography color="error">
              Error fetching Mars Classes!
            </Typography>
          </Box>
        }

        {
          !hasError && loadedData &&
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'id', sort: 'desc' }],
              },
            }}
            rows={loadedData}
            columns={sessionsColumnsDefinition}
            rowHeight={42}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            getRowClassName={(params) => `${params.row.timeClass}`}
          />
        }
      </Box>
    </Box>
  );
}
