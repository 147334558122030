import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import MarsClasses from './MarsClasses';




const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const Item = (props: PaperProps) => {
    return <StyledPaper {...props} elevation={0} />
}

export default function BasicGrid() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="column"
      columns={12}
      >
        <Grid item xs={12}>
          <Item>
            <MarsClasses/>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
